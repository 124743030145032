<script setup>
import { Link } from "@inertiajs/vue3";
import AppLayout from "@/Layouts/AppLayout.vue";
import { Head } from '@inertiajs/vue3'
import { availableCities, objectToQueryString,  HomeConfig } from "@/utils/hotelUtil"
/* import {HomeCard} from '@/Components/HomeCard.vue'
import {HomeCardMini} from '@/Components/HomeCardMini.vue' */


import { onMounted, computed, defineAsyncComponent } from 'vue'

import { useDisplay } from 'vuetify'
import HomeCard from "@/Components/HomeCard.vue";

const { mobile } = useDisplay()

onMounted(() => {
    console.log("ismobile=", mobile.value) // false
})

const props = defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
    mostRatedHotels: Array,
    mostVotedHotels: Array,
    totHotelsCount: Number
});
const dynamicHotel = computed(() => {
    const componentName = mobile.value ? 'HomeCard' : 'HomeCard';
    return defineAsyncComponent(() => import(`../Components/${componentName}.vue`))
});




</script>

<template>
    <div>

        <Head>
            <link rel="preload" as="image" href="/storage/images/darken.webp" />
        </Head>
        <app-layout :head="HomeConfig(totHotelsCount)">
            <div>
                <!-- Intro -->
                <section class="app-section">
                    <div class="container">
                        <div fetchpriority="high"
                            class="hidden preload bg-newbeach lg:min-h-[40vh] lg:h-20 rounded-3xl lg:flex items-end py-8 px-4 bg-cover bg-center">
                            <h2 class="mx-auto text-white app-title">
                                Basta leggere troppe recensioni per scegliere il <b>tuo</b> Hotel.
                            </h2>
                            <span class="mx-auto text-white  lg:text-2xl font-bold text-primary-600">Noi le abbiamo
                                lette tutte
                                e sappiamo quale è il miglior <b>hotel</b> per
                                <b>te</b></span>
                        </div>
                        <div class="lg:hidden">
                            <h2 class="mx-auto app-title text-xl font-bold text-primary-400 py-4  text-center">
                                Stanco di leggere recensioni?
                            </h2>
                        </div>
                    </div>
                </section>
                <section class="app-section">
                    <div class="container">
                        <div class="flex">
                            <div>
                                <span class="lg:text-2xl font-bold text-primary-600">Cerca una città e scegli le
                                    caratteristiche che ti
                                    interessano. </span>
                                <span class="mx-auto  lg:text-xl font-bold text-primary-600">E scopri il miglior
                                    <b>hotel</b> per <b>te</b>!

                                    <a :href="route('HowItWorks')" class="lg:hidden underline text-blue-600 hover:text-blue-800
                                         visited:text-purple-600 cursor-pointer text-small"> ( come funziona)</a>
                                </span>
                            </div>
                        </div>
                        <TheSearch />
                    </div>

                </section>


                <!-- Premium Listings -->
                <section class="app-section">
                    <div class="container flex flex-col gap-4">
                        <h2 class="app-title">Città Principali</h2>
                        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
                            <Link v-for="city in availableCities" :href="'/Search/' + city.name">
                            <CityCard :city="city.name" country="IT" />
                            </Link>
                        </div>
                    </div>
                </section>

                <!-- mostRatedHotels  -->
                <section class="app-section bg-primary-100">
                    <div class="container flex flex-col lg:gap-6">
                        <h2 class="app-title">Top Hotels</h2>
                        <div class="recent-homes">
                            <HomeCard v-for="hotel in mostRatedHotels" :key="hotel.id" :home="hotel" />
                        </div>
                    </div>
                </section>

                <!-- Featured Cities -->
                <!--       <section class="app-section">
        <div class="container flex flex-col gap-6">
          <h2 class="app-title">Strutture top</h2>

          <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-0">
            <Link href="/Search?lng=-122.4475775&lat=37.759986&city=San%20Francisco,%20CA,%20USA">
            <CityCard
              city="Golden Gate Bridge"
              country="San Francisco"
              class="md:rounded-r-none"
            />
            </Link>

            <Link href="/Search?lng=-86.8475&lat=21.16056&city=Cancún, Quintana Roo, Mexico">
            <CityCard
              city="Cancún"
              country="México"
              class="md:rounded-l-none"
            />
            </Link>
          </div>
        </div>
      </section> -->
                <!-- mostVotedHotels -->
                <section class="app-section bg-primary-100">
                    <div class="container flex flex-col lg:gap-6">
                        <h2 class="app-title">Strutture Più Votate</h2>
                        <div class="recent-homes">
                            <HomeCard v-for="hotel in mostVotedHotels" :key="hotel.id" :home="hotel" />
                        </div>
                    </div>
                </section>
            </div>
        </app-layout>
    </div>
</template>

<style lang="scss" scoped>
.recent-homes {
    @apply grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4;
}

.preload {
    background-image: url('/storage/images/darken.webp');
}
</style>
